@import '../../../static/variables.scss';

.invoices {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.invoice {
  display: flex;
  align-items: end;
  gap: 10px;
  &-info {
    display: flex;
    flex-direction: column;
    & button {
      width: 140px;
      margin-top: 10px;
    }
  }

  &-img {
    height: 150px;
    width: 100px;
  }
}

.img-modal {
  display: flex;
  flex-direction: column;
  gap: 10px;
  &-btn {
    width: 140px;
    align-self: flex-end;
  }
}

.cursor {
  cursor: pointer;
}

.error-message {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 150px;
  color: $brand-color;
  font-size: medium;
  background-color: black;
}
