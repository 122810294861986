@import 'variables';

body {
  background: $bg-color !important;
}

.button {
  background-color: $brand-color !important;
  color: #ffffff !important;
  border-radius: 0 !important;
  padding: 1em 3em !important;
  text-transform: uppercase !important;
  &:hover {
    background-color: $brand-color-hover !important;
  }
  &_positive {
    background-color: $positive-color !important;
    &:hover {
      background-color: $positive-color-hover !important;
    }
  }
}

.page {
  max-width: $page-width;
  padding: 2em 0;
  margin: 0 auto;
  background: $page-bg-color;
  &__header {
    font-size: 1.7em !important;
    text-align: center;
  }
}

.table-page {
  padding: 2em;
  margin-top: 4em;
  &__stats {
    text-align: right;
  }
}

.ReactTable .rt-thead.-header {
  min-height: 44px;
}

//semantic ui dimmer fix
.dimmer {
  max-width: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield !important;
}
