@import 'src/static/variables';

.dialogs {
  width: 97%;
  margin: 15px auto 0;
  padding: 0 !important;
  border: 1px solid rgba(0, 0, 0, 0.1);
  -webkit-border-top-left-radius: 10px;
  -webkit-border-top-right-radius: 10px;
  &__header {
    display: flex;
    padding-left: 10px;
    align-items: center;
    height: 25px;
    -webkit-border-top-left-radius: 10px;
    -webkit-border-top-right-radius: 10px;
    overflow: hidden;
    background-color: $brand-color;
    color: $page-bg-color;
  }
  &__items {
    position: relative;
    height: 150px;
    overflow: auto;
  }
  &__item {
    height: 50px;
    padding: 5px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    cursor: pointer;
    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
    &__header {
      display: flex;
      justify-content: space-between;
      font-weight: bold;
      &--name {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    &__message {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.loading {
  position: absolute;
  display: grid;
  place-items: center;
  background-color: rgba(0, 0, 0, 0.2);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  font-size: 16px;
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  &__label {
    color: #212121 !important;
  }
}
