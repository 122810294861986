$filter-input-gap: 0.7em;
$filters-width: 500px;
$range-filters-width: 40%;

.filters {
  max-width: $filters-width;
  margin: 0 auto;

  &__label {
    padding-bottom: $filter-input-gap / 2;
    display: block;
  }

  .input,
  .dropdown {
    width: 100%;
    min-width: auto !important;
  }

  &__filter-range-input {
    display: flex;
    flex-direction: column;
    p:first-of-type {
      font-weight: bold;
    }
    p {
      margin: 0 0 $filter-input-gap 0;
    }
    .field {
      margin-bottom: 0 !important;
    }
  }

  .filter-range-input {
    &__inputs {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .field {
        max-width: 48%;
      }
    }
  }

  .react-datepicker {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    &-wrapper {
      max-width: 48%;
      margin-bottom: $filter-input-gap;
    }
  }

  &__button {
    width: 100% !important;
    margin-bottom: $filter-input-gap !important;
  }

  &__input-header {
    margin: 0 0 $filter-input-gap 0;
  }

  //semantic ui
  .input,
  .dropdown {
    width: 100%;
  }

  .input,
  .dropdown,
  .checkbox {
    margin: 0 0 $filter-input-gap 0;
  }
}

.modal {
  &__input {
    margin-bottom: $filter-input-gap !important;
    width: 100% !important;
  }
}

//IE 11 fix
.ui.form .field .ui.input input,
.ui.form .fields .field .ui.input input {
  width: inherit !important;
}
