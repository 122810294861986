@import '../../static/variables';

.details-modal {
  &__content {
    margin: 0 auto;
  }

  &__input {
    width: 100%;
  }

  &__link {
    color: #4183c4;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
      color: #1e70bf;
    }
  }

  &__buttons {
    display: flex !important;
    margin-top: 10px;
    justify-content: space-between;
  }

  &__field {
    .button {
      min-width: 50%;
    }
  }

  &__tabs {
    width: 100% !important;
    margin: 2em 0;
  }

  .aliases {
    &__header {
      margin-bottom: 0 !important;
    }

    &__help {
      color: #8a8c8e;
      font-size: 0.95rem;
    }
  }
}

@media all and (max-width: 480px) {
  .details-modal {
    &__buttons {
      flex-wrap: wrap;

      .button:first-of-type {
        margin-bottom: 1em !important;
      }
    }
  }
}

.editable-cell {
  display: flex;
  justify-content: space-between;
  max-width: 100%;

  &:hover {
    cursor: pointer;
  }

  &__field {
    max-width: 80%;
    width: 80%;
    display: inline-block;
    overflow-x: hidden;
  }

  &__edit-icon {
    padding-left: 8px;

    &:hover {
      color: $brand-color;
    }
  }
}

.map {
  width: 100% !important;
  height: 200px !important;
}

//Semantic UI
.checkbox {
  display: block !important;
}
.error-popup {
  white-space: normal;
  background: #fff !important;
  border: 1px solid #e0b4b4 !important;
  color: #9f3a38 !important;
  height: 28px;
  font-weight: 700 !important;
  font-size: 12px !important;
  padding: 7px 10px !important;

  .content {
    padding: 0 !important;
    margin: 0 !important;
    line-height: 1 !important;
  }
}

.offense-modal-table th {
  text-align: center !important;
}
