@import 'src/static/variables';

.active-dialogs {
  display: flex;
  border: 1px solid $border-color;
  margin: 0 auto !important;
  width: 100%;
  max-width: 1400px;

  &__header {
    display: flex;
    justify-content: space-between;
    padding: 0 !important;
    width: 100%;
    height: 42px;
    align-items: center;
    border-bottom: 1px solid $border-color;
    font-size: 18px;
    font-weight: bold;

    div {
      margin: auto;
    }
  }

  &__category {
    width: 100%;
    max-width: 400px;
  }

  &__chat {
    height: 700px;
    padding: 0 !important;
  }

  &__user-info {
    max-width: 400px;
    width: 100%;
  }
}

.user-info {
  display: flex;
  margin: 15px 5px;
}
