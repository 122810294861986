@import '../../../static/variables.scss';

.admins-page {
  &__actions {
    display: flex !important;
    justify-content: flex-end;
  }
  &__input {
    margin-bottom: $input-margin !important;
  }
}
