@import 'src/static/variables';

.chat {
  height: 100%;
  width: 600px;
  border-right: 1px solid $border-color;
  border-left: 1px solid $border-color;

  &__header {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 42px;
    font-size: 18px;
    font-weight: bold;
    border-bottom: 1px solid $border-color;

    button {
      text-align: center !important;
      width: 33%;
      height: 100%;
      box-sizing: border-box !important;
      margin: 0 !important;
      padding: 0 !important;
    }

    &--name {
      margin: auto;
      width: 33%;
      padding: 10px;
      height: 100%;
      text-align: center;
    }

    &--assign {
      width: 33%;
      height: 100%;
      padding: 10px;
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: $positive-color;
      font-size: 16px;
      border-right: 1px solid $border-color;
      transition: 0.1s;
      cursor: default;

      &:hover {
        background-color: #eeeded;
        overflow: visible;
        white-space: normal;
        text-overflow: inherit;
        font-size: 12px;
        line-height: 12px;
      }
    }
  }

  &__field {
    height: 100%;
    width: 100%;

    &__messages {
      display: flex;
      flex-direction: column;
      align-items: center;
      overflow: auto;

      .admin-message {
        align-self: flex-end;
        max-width: 60%;
        float: right;

        &--color {
          background-color: $brand-color;
          color: $page-bg-color;
        }
      }

      .user-message {
        align-self: flex-start;
        max-width: 60%;
        float: left;

        &--color {
          background-color: $bg-color;
        }
      }

      &__no-messages {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        h2 {
          opacity: 0.5;
        }
      }
    }

    &__message {
      margin: 15px 10px 0;
      padding: 7px 15px;
      border-radius: 20px;
      word-wrap: break-word;
    }

    &__time {
      float: inherit;
      margin: 0 15px;
      font-size: 10px;
      color: $text-color;
    }

    &__input {
      position: relative;

      input {
        border-left: 0 !important;
        border-radius: 0 !important;

        &:focus {
          border-left: 1px solid #85b7d9 !important;
        }
      }

      .ui.icon.input > i.icon {
        opacity: 1;
        color: $page-bg-color;
      }

      .ui.labeled.icon.button {
        margin-right: 0 !important;
      }

      &--upload {
        button {
          top: 6px;
          right: 180px;
          position: absolute !important;
          background-color: $page-bg-color !important;
          color: $text-color !important;
          border-radius: 20px !important;
          padding: 5px !important;
          border: none !important;
          outline: none !important;
          cursor: pointer;

          &:hover {
            background-color: $border-color !important;
            outline: none !important;
          }

          &:focus {
            outline: none !important;
          }

          .icon {
            font-size: 18px;
          }
        }

        &-success {
          button {
            color: #81be39 !important;
          }
        }
      }
    }

    &__image-trigger {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 150px;
      height: 150px;
      cursor: pointer;
      background-color: white;
      border-radius: 10px;
      color: $text-color;
      overflow: hidden;

      &__text {
        text-align: center;
        padding-top: 60px;
        font-size: 16px;
      }

      & > img {
        margin: auto;
        width: 100%;
        display: block;
        text-align: center;
      }
    }
  }
}
