.retail-point-page {
  &__confirmation {
    max-width: 600px;
    margin: 0 auto !important;
  }
  .confirmation {
    &__actions {
      display: flex !important;
      justify-content: space-between;
    }
    &__header {
      width: 100%;
      margin-top: 1em;
      text-align: center;
    }
    &__data-card {
      max-width: 400px;
      margin: 0 auto !important;
    }
  }
}
