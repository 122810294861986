@import '../../static/variables';

$table-striped-color: $bg-color;
$clickable-hover-color: rgb(235, 230, 230);

.custom-table {
  //react-table
  .-pagination {
    flex-wrap: nowrap !important;
    input,
    select {
      color: $text-color;
    }
  }
  //next & prev button divs
  .-next,
  .-previous {
    flex: 0 0 !important;
    //buttons
    button {
      background: none !important;
      color: $text-color !important;
    }
  }
  .-center {
    justify-content: center !important;
  }
  .-odd {
    background-color: $table-striped-color !important;
  }
  .rt-tbody .rt-tr {
    height: 70px;
    align-items: center !important;
  }
  .rt-tr {
    align-items: stretch !important;
  }
  .rt-thead,
  .rt-resizable-header-content {
    overflow: visible !important;
    white-space: pre-wrap;
  }
  .rt-resizable-header-content {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &_clickable {
    &:hover,
    &.-odd:hover {
      cursor: pointer;
      background: $clickable-hover-color !important;
    }
  }
}
.-pageInfo {
  display: inline-flex !important;
  align-items: center !important;
  justify-content: space-between;
  .-pageJump {
    padding-left: 0.4em;
    padding-right: 0.4em;
  }
  .-totalPages {
    padding-left: 0.4em;
  }
}
