@import '../../../static/variables.scss';

.legal-sale-header {
  display: flex;
  white-space: nowrap;
}

.same-inn-warning {
  color: $brand-color;
}
