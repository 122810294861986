.page-anti-fraud-settings {
  width: 476px;
  margin-top: 70px !important;

  form {
    padding: 16px 30px 0;
  }

  label {
    font-family: 'Lato', system-ui, Avenir, Helvetica, Arial, sans-serif !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 17px !important;
    color: #212121 !important;
    margin-bottom: 15px !important;
  }

  input {
    margin-bottom: 6px !important;
  }

  button {
    display: block !important;
    margin: 40px auto !important;
    width: 276.5px !important;
  }

  .error.field {
    .label {
      width: 100%;
    }
  }
}
