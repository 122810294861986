.reports {
  margin: 2.5rem 0;

  &__header {
    margin-bottom: 2.5rem !important;
  }

  .filters {
    max-width: 50%;
    @media screen and (max-width: 800px) {
      max-width: 90%;
    }
  }

  .form {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;

    .button {
      max-height: 38px;
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }

    .filters__filter-range-input {
      margin-right: 0.7rem;
    }

    .dropdown {
      max-width: 300px;
      margin-right: 0.7rem;
    }
  }
}
